// COMPONENTS
import React from 'react'
import Container from 'react-bootstrap/Container'
import Section from 'layout/Section'
import SocialList from 'LandingPage/SocialList'
// NPM
import dateFormat from 'dateformat'

// Blog post title component
const Title = ({
  title,
  subtitle,
  author,
  socials,
  date,
  image,
  roundedImage,
  error,
  // content,
  // section,
}) => {
  let formattedDate = date ? dateFormat(date, 'fullDate') : null
  // date is Date object
  /** 3 ways to use a title component
   * #1 title for blog post
   * #2 title for profile
   * #3 title for error page
   */
  // const { title, subtitle, imageUrl, author, socials, date, section, error } = content

  return (
  <Section content={{id:'title', inverted:false, endcap:'none', title:null, subtitle:null}}>
    <Container>
      {title && <h1 className={`display-3 pb-5 ${error && 'text-danger'}`}> {title}</h1>}
      {image &&
        <img
          className={`rounded${roundedImage && '-circle'} mx-auto d-block`}
          src={image}
          alt='thumbnail'
          style={{ width: '50%'}}
        />
      }
      {subtitle && <h1 className={`display-6 pt-5 ${error && 'text-danger'}`}> {subtitle}</h1>}
      {author && <h5 className='text-muted'>{author}</h5>}
      {socials && <SocialList socialsList={socials.socialList} />}
      {formattedDate && <h6 className='text-muted'>{formattedDate}</h6>}
    </Container>
  </Section>
  )
}

Title.defaultProps = {
  error: false,
}

export default Title