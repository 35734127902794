// COMPONENTS
import React from 'react'
import ContentContainer from 'layout/ContentContainer'
// NPM
import marked from 'marked'

// Blog post body component
// Handles either html or markdown formats
const Body = ({ html, markdown }) => {
  if (html || markdown) {
    return (
      <ContentContainer type='body'>
        {html && <div dangerouslySetInnerHTML={{ __html: html }} />}
        {markdown && (
          <div dangerouslySetInnerHTML={{ __html: marked(markdown) }} />
        )}
      </ContentContainer>
    )
  } else {
    return <></>
  }
}
export default Body
