// COMPONENTS
import React from 'react'
import Section from 'layout/Section'
import SignUp from 'components/SignUp'

import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'

// CONTENT
import signupContent from 'content/signup.yml'

// Blog post footer
const PostFooter = ({ signup, inverted = false }) => {
  const section = {
    id: 'post-footer',
  }

  return (
  <Section content={section}>
    <Container fluid>
      <Row>
        <Col className='py-5' xs={12}>
          <Button href='/posts' size='lg' variant='info'>
            back to blog posts
          </Button>
        </Col>
        {signup && <SignUp content={signupContent} inverted={inverted} />}
      </Row>
    </Container>
  </Section>
  )
}

PostFooter.defaultProps = {
  signup: true,
}

export default PostFooter
