// COMPONENTS
import React from 'react'
// BOOTSTRAP
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
// ICONS
import Icon from 'elements/Icon'

// Social list component
const SocialList = ({ socialsList }) => {
  return (
    <Container>
      <Row>
        <Col className='mt-5' xs={12}>
          <Row className='justify-content-center mb-2'>
            {socialsList.map(social => (
              <Col className='py-4' md={2} xs={4} key={social.title}>
                <a href={social.link} target='_blank' rel='noreferrer'>
                  {social.icon && <Icon name={social.icon} /> }
                </a>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </Container>
  )
}

export default SocialList
